<template>
  <div id="app-base-db" class="app-base-db">
    <div class="imgheader">
          <div class="imgtitle">图片预览</div>
          <div class="imgtitleaction" @click="minimg()">
            <div class="close"  ></div>
          </div>
        </div>
        <div class="imgbox">
          <img :src="preimgurl" draggable="true" :style="{transform:'scale('+multiples+')'}"  @dragstart="dragstart($event)" @dragend="dragend($event)" >
        </div>
        <div class="imgaction">
          <a href="#" style="margin-left: 10px;"  @click="sximg"><img src="@/assets/suoxiao.png"/></a>
          <a href="#" style="margin-left: 10px;"  @click="pfimg"><img src="@/assets/fangda.png"/></a>
          <a :href="preimgurl" style="margin-left: 10px;" download><img src="@/assets/xiazai.png"/></a>
        </div>
  </div> 
</template>
<script>
import { ipcApiRoute , requestHttpPost , requestHttpGet} from '@/api/main'
import storage from 'store2'
export default {
  data() {
    return {
      preimgurl:"",
      multiples:1
    };
  },
  mounted() {
   
  },
  created(){
    this.preimgurl = storage.get("preImages")
  },
  methods: {
    sximg(){
      if (this.multiples <= 1) {
        return;
      }       
      this.multiples -= 0.25;
    },
    pfimg(){
      if (this.multiples >= 2) {
        return;
      }       
      this.multiples += 0.25;
    },
    minimg(){ 
      this.$ipc.invoke(ipcApiRoute.closeWinByName, 'preimg').then(result => {
          console.log(result)
      })
    },
  }
};
</script>
<style lang="less" scoped>
.app-base-db{
  padding: 16px;
  background-color: #fff;
  border-radius: 10px;
}
.imgbox{
  display: flex;
  justify-content: center;
  height: 540px;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.imgbox img{
  max-width: 80%;
  max-height: 640px;
  position: absolute;
  z-index: 10;
  user-select: none;
}
.imgaction{
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  margin: auto;
}
.imgtitleaction{
  position: absolute;
  right: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  background-color: #ED2616;
}
.close {
  width: 16px;
  height: 16px;
  background-image: url('~@/assets/main-close-w.png');
  background-size: 100%;
  margin-top: 7px;
  margin-left: 7px;
  -webkit-app-region: no-drag;
}
.imgheader{
  -webkit-app-region: drag;
  -webkit-user-select: none;
}
</style>
